<template>
  <v-card class="exhibitor-presentation pa-2" elevation="0">
    <v-card-title class="exhibitor-presentation__title ma-2">
      <v-avatar class="exhibitor-presentation__logo mr-4">
        <img v-if="exhibitor.kioskOptions.logo" :src="exhibitor.kioskOptions.logo" alt="logo" />
      </v-avatar>
      {{ exhibitor.name }}
    </v-card-title>

    <div class="d-flex flex-wrap mb-2 ml-2 ga-2">
      <v-btn
        v-if="exhibitor.email"
        elevation="0"
        min-width="38"
        color="transparent"
        data-test-id="email-btn"
        :href="`mailto:${exhibitor.email}`"
        @click="clickEmail"
      >
        <v-icon class="rounded-circle icon pa-2 exhibitor-presentation__icon">
          mdi-email-outline
        </v-icon>
      </v-btn>

      <v-btn
        v-if="exhibitor.website"
        elevation="0"
        min-width="38"
        color="transparent"
        target="_blank"
        data-test-id="website-btn"
        :href="exhibitor.website"
        @click="clickWebsite"
      >
        <v-icon class="rounded-circle icon pa-2 exhibitor-presentation__icon"> mdi-web </v-icon>
      </v-btn>

      <v-btn
        v-if="exhibitor.phoneNumber"
        elevation="0"
        min-width="38"
        color="transparent"
        data-test-id="phone-btn"
        :href="`tel:${exhibitor.phoneNumber}`"
        @click="clickPhoneNumber"
      >
        <v-icon class="rounded-circle icon pa-2 exhibitor-presentation__icon">
          mdi-phone-outline
        </v-icon>
      </v-btn>

      <v-btn
        v-if="exhibitor.address"
        elevation="0"
        min-width="38"
        color="transparent"
        data-test-id="address-btn"
        @click="clickAddress"
      >
        <v-tooltip right v-model="show">
          <template v-slot:activator="{}">
            <v-icon class="rounded-circle icon pa-2 exhibitor-presentation__icon" color="black">
              mdi-map-marker-outline
            </v-icon>
          </template>

          <span class="exhibitor-presentation__address">
            {{ exhibitor.address }}
          </span>
        </v-tooltip>
      </v-btn>
    </div>

    <div class="d-flex flex-wrap ml-2 ga-2">
      <v-btn
        v-if="exhibitor.facebook"
        elevation="0"
        min-width="38"
        data-test-id="facebook-btn"
        color="transparent"
        :href="exhibitor.facebook"
        target="_blank"
        @click="clickFacebook"
      >
        <v-icon class="rounded-circle icon pa-2 exhibitor-presentation__icon" color="black">
          mdi-facebook
        </v-icon>
      </v-btn>

      <v-btn
        v-if="exhibitor.instagram"
        elevation="0"
        min-width="38"
        data-test-id="instagram-btn"
        color="transparent"
        :href="exhibitor.instagram"
        target="_blank"
        @click="clickInstagram"
      >
        <v-icon class="rounded-circle icon pa-2 exhibitor-presentation__icon">
          mdi-instagram
        </v-icon>
      </v-btn>

      <v-btn
        v-if="exhibitor.linkedin"
        elevation="0"
        min-width="38"
        data-test-id="linkedin-btn"
        color="transparent"
        :href="exhibitor.linkedin"
        target="_blank"
        @click="clickLinkedin"
      >
        <v-icon class="rounded-circle icon pa-2 exhibitor-presentation__icon">
          mdi-linkedin
        </v-icon>
      </v-btn>

      <v-btn
        v-if="exhibitor.twitter"
        elevation="0"
        min-width="38"
        data-test-id="twitter-btn"
        color="transparent"
        :href="exhibitor.twitter"
        target="_blank"
        @click="clickTwitter"
      >
        <v-icon class="rounded-circle icon pa-2 exhibitor-presentation__icon"> mdi-twitter</v-icon>
      </v-btn>

      <v-btn
        v-if="exhibitor.youtube"
        elevation="0"
        min-width="38"
        data-test-id="youtube-btn"
        color="transparent"
        :href="exhibitor.youtube"
        target="_blank"
        @click="clickYoutube"
      >
        <v-icon class="rounded-circle icon pa-2 exhibitor-presentation__icon"> mdi-youtube</v-icon>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import {
  CLICK_EXHIBITOR,
  EXHIBITOR_PHONE,
  EXHIBITOR_EMAIL,
  EXHIBITOR_ADDRESS,
  EXHIBITOR_FACEBOOK,
  EXHIBITOR_INSTAGRAM,
  EXHIBITOR_LINKEDIN,
  EXHIBITOR_YOUTUBE,
  EXHIBITOR_TWITTER,
  EXHIBITOR_WEBSITE,
  getStatSocket,
} from '@/socket/stat-namespace';

export default {
  name: 'ExhibitorPresentation',
  components: {},
  props: {
    exhibitor: {
      type: Object,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    show: false,
  }),
  methods: {
    emitClickEvent(type) {
      getStatSocket().emit(CLICK_EXHIBITOR, this.event.id, this.exhibitor.id, type);
    },
    clickPhoneNumber() {
      this.emitClickEvent(EXHIBITOR_PHONE);
    },
    clickEmail() {
      this.emitClickEvent(EXHIBITOR_EMAIL);
    },
    clickAddress() {
      this.show = !this.show;
      this.emitClickEvent(EXHIBITOR_ADDRESS);
    },
    clickFacebook() {
      this.emitClickEvent(EXHIBITOR_FACEBOOK);
    },
    clickInstagram() {
      this.emitClickEvent(EXHIBITOR_INSTAGRAM);
    },
    clickLinkedin() {
      this.emitClickEvent(EXHIBITOR_LINKEDIN);
    },
    clickYoutube() {
      this.emitClickEvent(EXHIBITOR_YOUTUBE);
    },
    clickTwitter() {
      this.emitClickEvent(EXHIBITOR_TWITTER);
    },
    clickWebsite() {
      this.emitClickEvent(EXHIBITOR_WEBSITE);
    },
  },
};
</script>

<style lang="scss" scoped>
.exhibitor-presentation {
  background-color: transparent;
  color: white;

  &__title {
    display: flex;
  }

  &__logo {
    img {
      max-width: 100%;
      max-height: 100%;
    }

    margin-bottom: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 50%;
    height: auto !important;
    width: auto !important;
  }

  &__text {
    color: white !important;
  }

  &__icon {
    background-color: white;
    color: black !important;
  }

  &__address {
    pointer-events: visible;
  }
}
</style>
