<template>
  <div class="exhibitor-presenters">
    <h3 class="pl-2 pb-2">{{ $t('lobby.exhibitors.presenters.title') }}</h3>

    <div class="exhibitor-presenters__infos">
      <v-slide-group
        v-model="selectedPresenterIndex"
        class="slider mb-3"
        mandatory
        max="1"
        active-class="exhibitor-presenters__infos--selected"
        show-arrows
        mobile-breakpoint="sm"
      >
        <template #prev>
          <v-btn elevation="0" class="flex-grow-0" icon large color="white">
            <v-icon dark medium> mdi-chevron-left</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-slide-item
            class="pb-10 pt-10"
            v-for="presenter in presenters"
            :key="presenter.id"
            v-slot="{ toggle }"
          >
            <v-btn
              class="presenter-btn"
              elevation="0"
              max-height="40px"
              :large="!isMobile"
              depressed
              color="transparent"
              @click="toggle"
            >
              <v-avatar size="60" color="white">
                <img v-if="presenter.avatar" :src="presenter.avatar" alt="logo" />
                <template v-else>
                  {{ `${presenter.firstName[0]} ${presenter.lastName.toUpperCase()}` }}
                </template>
              </v-avatar>
            </v-btn>
          </v-slide-item>
        </template>

        <template #next>
          <v-btn icon large color="white">
            <v-icon dark medium> mdi-chevron-right</v-icon>
          </v-btn>
        </template>
      </v-slide-group>
    </div>

    <v-divider :thickness="17" color="white"></v-divider>

    <div class="mb-4" v-if="selectedPresenter">
      <v-card width="100%" elevation="0" class="exhibitor-presenter__selected">
        <v-container class="px-2 d-flex align-items-center" fluid>
          <div class="mr-10">
            <v-avatar size="100" color="white">
              <img v-if="selectedPresenter.avatar" :src="selectedPresenter.avatar" alt="logo" />
              <template v-else>
                {{ `${selectedPresenter.firstName} ${selectedPresenter.lastName}` }}
              </template>
            </v-avatar>
          </div>

          <div class="exhibitor-presenter__selected-presentation">
            <section class="d-flex flex-column align-items-start">
              {{ `${selectedPresenter.firstName} ${selectedPresenter.lastName}` }}

              <div class="infos__actions mb-6" v-if="event.exhibitorsStatus === 'active'">
                <div class="exhibitor-presenter__actions d-flex flex-wrap" v-if="selectedPresenter">
                  <v-btn elevation="0" small color="white" @click.native="openChat">
                    <v-icon color="black">mdi-message-text-outline</v-icon>
                  </v-btn>

                  <v-btn
                    elevation="0"
                    small
                    color="white"
                    :class="{ disabled: this.selectedPresenter.status === 'offline' }"
                    @click.native="showVideoChat"
                  >
                    <v-icon color="black">mdi-message-video</v-icon>
                  </v-btn>

                  <v-btn
                    small
                    elevation="0"
                    color="white"
                    v-if="getUserHasAppointment(selectedPresenter.id)"
                    :to="{
                      name: 'Booking',
                      params: {
                        eventId,
                        userId: this.selectedPresenter.id,
                      },
                    }"
                    data-test-id="schedule-btn"
                    @click="clickAppointment"
                  >
                    <v-icon color="black">mdi-calendar-month-outline</v-icon>
                  </v-btn>
                </div>
              </div>

              <p class="body-2 white--text ql-editor" v-html="selectedPresenter.about"></p>
            </section>
          </div>
        </v-container>
      </v-card>
    </div>

    <video-chat
      :user="exhibitor"
      :video-chat="videoChat"
      :video-chat-url="videoChatUrl"
      @hide-video="hideVideoChat"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import VideoChat from '@/components/video-chat/VideoChat.vue';

import { APP_CHATS_MODULE, OPEN_CHAT } from '@/stores/umanize-app/actions/chats/chats.actions';
import {
  APPOINTMENT_MODULE,
  CHECK_USERS_HAVE_APPOINTMENT,
} from '@/stores/umanize-app/actions/appointment/appointment.action';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';

import { whereByIframeSrcBuilder } from '@/helpers/whereby/whereby.helper';
import {
  CLICK_EXHIBITOR,
  EXHIBITOR_APPOINTMENT,
  EXHIBITOR_CHAT,
  EXHIBITOR_VIDEOCHAT,
  getStatSocket,
} from '@/socket/stat-namespace';

export default {
  name: 'ExhibitorPresenters',
  components: {
    VideoChat,
  },
  data: () => ({
    selectedPresenterIndex: 0,
    videoChat: false,
    videoChatUrl: null,
  }),
  props: {
    exhibitor: {
      type: Object,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(APPOINTMENT_MODULE, ['getUserHasAppointment']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUser']),
    presenters() {
      return this.exhibitor.users || [];
    },
    selectedPresenter() {
      return this.presenters[this.$data.selectedPresenterIndex];
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    eventId() {
      return this.$route.params.eventId;
    },
  },
  methods: {
    ...mapActions(APP_CHATS_MODULE, [OPEN_CHAT]),
    ...mapActions(APPOINTMENT_MODULE, [CHECK_USERS_HAVE_APPOINTMENT]),
    emitClickEvent(type) {
      getStatSocket().emit(CLICK_EXHIBITOR, this.event.id, this.exhibitor.id, type);
    },
    emitStatEvent(event) {
      const { eventId, exhibitorId } = this.$route.params;

      getStatSocket().emit(event, eventId, exhibitorId);
    },
    openChat() {
      this[OPEN_CHAT]({ userIds: [this.selectedPresenter.id] });
      this.emitClickEvent(EXHIBITOR_CHAT);
    },
    showVideoChat() {
      const url = whereByIframeSrcBuilder(this.selectedPresenter.wherebyUrl, {
        embed: true,
        background: 'on',
        chat: 'on',
        people: 'off',
        screenShare: 'on',
        recording: 'on',
        displayName: `${this.loggedInUser.firstName} ${this.loggedInUser.lastName}`,
      });

      this.videoChatUrl = url;
      this.videoChat = true;
      this.emitClickEvent(EXHIBITOR_VIDEOCHAT);
    },
    hideVideoChat() {
      this.videoChat = false;
      this.videoChatUrl = null;
    },
    clickAppointment() {
      this.emitClickEvent(EXHIBITOR_APPOINTMENT);
    },
  },
};
</script>

<style lang="scss" scoped>
.exhibitor-presenters {
  &__infos--selected {
    opacity: 0.6;
  }

  & .ql-editor {
    ::v-deep img {
      max-width: 100%;
    }
  }

  .exhibitor-presenter {
    &__selected {
      background-color: transparent !important;
      color: white !important;
    }

    &__selected-presentation {
      flex: 1 1 auto;
      min-width: 0;
    }

    &__actions {
      gap: 3px;
    }
  }
}
</style>
