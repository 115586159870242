<template>
  <div>
    <div class="preview-actions d-flex flex-column align-center mb-8">
      <div
        v-if="hasVideo"
        class="preview-actions__video d-flex justify-center align-center mt-3 mb-3 px-10 py-5"
        :style="{ 'background-image': `url(${videoThumbnail})` }"
      >
        <v-btn
          class="flex-grow-0 align-self-center white"
          icon
          elevation="2"
          height="45"
          width="45"
          :color="accentColor"
          @click="openVideo"
        >
          <v-icon large class="video__icon">mdi-play-outline</v-icon>
        </v-btn>
      </div>

      <v-card class="preview-actions__documents mt-3 mb-2" v-if="hasDocument">
        <v-toolbar dense>
          <v-toolbar-title class="text-h6 documents__title">
            {{ $t('exhibitor.documents') }}
          </v-toolbar-title>
        </v-toolbar>

        <v-card-actions class="documents__items d-flex justify-center align-center flex-wrap">
          <div
            class="d-flex flex-column align-center justify-center py-3"
            v-for="document in documents"
            :key="document.id"
          >
            <v-btn
              class="items__button"
              :href="document.url"
              target="_blank"
              :style="{
                'background-image': 'url(' + exhibitor.kioskOptions.logo + ')',
              }"
              download
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
            <span class="documents__name">{{ document.name }}</span>
          </div>
        </v-card-actions>
      </v-card>
    </div>

    <v-dialog
      :content-class="!isMobile ? '' : 'v-dialog--overlay'"
      v-model="videoDialog"
      :fullscreen="isMobile"
      width="600"
    >
      <v-card>
        <v-toolbar dark :color="accentColor">
          <v-spacer></v-spacer>
          <v-btn :color="accentColor" icon dark @click="hideVideo">
            <v-icon :color="btnTextColor">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <vimeo-video v-if="exhibitorVideo && videoDialog" :videoUrl="exhibitorVideo" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import VimeoVideo from '@/components/video/Video.vue';
import { APP_CUSTOM_UI_MODULE } from '@/stores/umanize-app/actions/custom-ui/app-custom-ui.actions';

export default {
  name: 'Exhibitor',
  components: {
    VimeoVideo,
  },
  props: {
    exhibitor: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    videoDialog: false,
    exhibitorVideo: null,
  }),
  computed: {
    ...mapGetters(APP_CUSTOM_UI_MODULE, ['customUi']),
    hasVideo() {
      return this.exhibitor.kioskOptions.hasMainVideo;
    },
    hasDocument() {
      return this.exhibitor?.documents?.length > 0;
    },
    documents() {
      return this.exhibitor.documents || [];
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    videoThumbnail() {
      return (
        this.exhibitor?.kioskOptions?.videoThumbnail ||
        this.exhibitor?.kioskOptions?.logo ||
        this.event?.coverImages[0]
      );
    },
    btnTextColor() {
      return this.customUi?.btnTextColor || 'primary';
    },
    accentColor() {
      return this.customUi?.btnColor || 'primary';
    },
  },

  methods: {
    openVideo() {
      this.videoDialog = true;
      this.exhibitorVideo = this.exhibitor.kioskOptions.playableMainVideoUrl;
    },
    hideVideo() {
      this.videoDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../styles/core/variables';
@import '../../../../styles/core/mixins';

.preview-actions {
  background-color: white;

  &__video {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
  }

  &__documents {
    width: 100%;
    max-width: 75%;
  }

  .documents {
    height: 100%;

    &__title {
      margin: 0 auto;

      &:before {
        font-family: 'Material Design Icons';
        content: '\F0224';
        font-size: 30px;
        font-weight: $regular;
        position: absolute;
        left: 10px;
      }
    }

    &__name {
      white-space: pre-line;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
      max-width: 100%;
    }
  }

  .video {
    &__icon {
      padding: 2px 0 0 4px;
    }
  }
}
</style>
