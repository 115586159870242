<template>
  <div class="exhibitor-selected-section d-flex flex-column">
    <div class="d-flex justify-space-between">
      <h2 class="ma-2">{{ this.section.title }}</h2>

      <v-btn icon color="black" @click="$emit('clear-selected-section')" data-test-id="delete">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <div
      class="exhibitor-selected-section__body black--text ql-editor full-width"
      data-gramm="false"
      spellcheck="false"
      v-html="section.body"
    ></div>

    <div>
      <div v-if="hasResources">
        <h2 class="ma-2">{{ $t('exhibitor.documents') }}</h2>
        <div class="exhibitor-selected-section__resources">
          <div
            class="exhibitor-selected-section__resources-item d-flex flex-column align-start justify-start py-3 ms-2"
            v-for="document in section.resources"
            :key="document.id"
          >
            <v-btn
              :style="{
                'background-image': 'url(' + backgroundImageUrl + ')',
              }"
              target="_blank"
              :href="document.url"
              download
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>

            <span>{{ document.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExhibitorSelectedSection',
  props: {
    section: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
    },
    backgroundImageUrl: {
      type: String,
    },
  },
  computed: {
    hasResources() {
      return !!this.section.resources?.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.exhibitor-selected-section {
  min-width: 100%;
  max-width: 100%;

  &__body {
    ::v-deep img {
      max-width: 100%;
    }
  }
  &__resources {
    display: grid;
    grid-template-columns: repeat(auto-fill, 7rem);
    grid-column-gap: 6px;
    grid-row-gap: 6px;
    justify-content: center;
  }
}
</style>
