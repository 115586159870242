<template>
  <v-sheet class="exhibitor d-flex flex-column pa-4" :dark="theme === 'dark'">
    <h3 class="exhibitor__title">{{ name }}</h3>

    <div class="exhibitor__reps" v-if="hasReps">
      <relation
        class="rep"
        v-for="rep in reps"
        :relation="rep"
        :is-contact="isRepConnected(rep)"
        :show-calendar="getUserHasAppointment(rep.id)"
        :loggedUserId="loggedInUser.id"
        @add-connection="addConnection"
        @remove-connection="removeConnection"
        @go-to-profile="goToProfile"
        @chat="openChat"
        @booking="booking"
        :key="`rep-${rep.id}`"
      />
    </div>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Relation from '@/components/relation/Relation.vue';

import { toUserBooking } from '@/navigation';

import {
  ADD_TO_CONTACTS,
  RELATIONS_MODULE,
  REMOVE_FROM_CONTACTS,
} from '@/stores/umanize-app/actions/relations/relations.actions';
import { APP_CHATS_MODULE, OPEN_CHAT } from '@/stores/umanize-app/actions/chats/chats.actions';
import { APPOINTMENT_MODULE } from '@/stores/umanize-app/actions/appointment/appointment.action';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';

/**
 * Renders an exhibitor and its reps.
 *
 * NOTE: for performance reasons, this component expects the current
 * user's relations (relations store module) to be loaded. Otherwise,
 * we'd be loading the relations for each exhibitor on a given page,
 * and we don't want that (duh)
 */
export default {
  name: 'Exhibitor',
  components: { Relation },
  props: {
    name: {
      type: String,
      required: true,
    },
    reps: {
      type: Array,
      required: false,
    },
    theme: {
      type: String,
      required: false,
      default: 'light',
    },
  },
  computed: {
    ...mapGetters(RELATIONS_MODULE, ['isConnected']),
    ...mapGetters(APPOINTMENT_MODULE, ['getUserHasAppointment']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUser']),
    hasReps() {
      return (this.reps || []).length > 0;
    },
    eventId() {
      return this.$route.params.eventId;
    },
  },
  methods: {
    ...mapActions(APP_CHATS_MODULE, [OPEN_CHAT]),
    ...mapActions(RELATIONS_MODULE, [ADD_TO_CONTACTS, REMOVE_FROM_CONTACTS]),
    isRepConnected(rep) {
      return this.isConnected(rep.id);
    },
    addConnection(relation) {
      this[ADD_TO_CONTACTS](relation);
    },
    goToProfile(userId) {
      this.$router.push({ name: 'EventPublicProfile', params: { userId, eventId: this.eventId } });
    },
    removeConnection(relation) {
      this[REMOVE_FROM_CONTACTS](relation);
    },
    openChat(relation) {
      this[OPEN_CHAT]({ userIds: [relation.id] });
    },
    booking(relation) {
      toUserBooking(this.$router)({
        eventId: this.eventId,
        userId: relation.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../styles/core/variables';

.exhibitor {
  &__reps {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-auto-rows: 160px;
    grid-gap: 2px;
  }

  @include breakpoint(small) {
    &__reps {
      grid-gap: 5px;
    }
  }
}
</style>
