<template>
  <v-card class="exhibitor-section mb-2 d-flex flex-column">
    <v-card-title>
      <h4 class="exhibitor-section__title">
        {{ section.title }}
      </h4>
    </v-card-title>

    <v-divider class="mb-2"></v-divider>

    <div class="d-flex flex-wrap ma-2 pl-5">
      <span
        v-for="(tag, index) in section.tags"
        :key="`tags-${index}`"
        class="exhibitor-section__tags"
      >
        {{ tag }}
      </span>
    </div>

    <v-card-text>
      <v-divider></v-divider>
      <div
        class="exhibitor-section__summary black--text ql-editor"
        data-gramm="false"
        spellcheck="false"
        v-html="section.summary"
      ></div>
    </v-card-text>

    <v-btn
      large
      depressed
      color="black"
      class="exhibitor-section__more mb-5 mr-5 ml-5"
      @click="$emit('select-section', section)"
    >
      {{ $t('lobby.exhibitors.section.learnMore') }}
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-card>
</template>

<script>
export default {
  name: 'ExhibitorSection',
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.exhibitor-section {
  &__title {
    word-break: break-word;
  }

  &__tags {
    flex: 50%;
    font-weight: bold;
  }

  &__more {
    color: white;
  }

  &__summary {
    ::v-deep img {
      max-width: 100%;
    }
  }
}
</style>
