import { render, staticRenderFns } from "./ExhibitorPresentation.vue?vue&type=template&id=3910f5e4&scoped=true&"
import script from "./ExhibitorPresentation.vue?vue&type=script&lang=js&"
export * from "./ExhibitorPresentation.vue?vue&type=script&lang=js&"
import style0 from "./ExhibitorPresentation.vue?vue&type=style&index=0&id=3910f5e4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3910f5e4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAvatar,VBtn,VCard,VCardTitle,VIcon,VTooltip})
