<template>
  <div class="exhibitors-list">
    <div class="d-flex flex-row flex-wrap" v-if="hasExhibitors">
      <exhibitor
        class="exhibitor-list__item mb-4"
        v-for="(exh, idx) in exhibitors"
        :name="exh.name"
        :theme="getThemeColor(idx)"
        :reps="exh.users"
        :key="`exh-${exh.id}`"
      />
    </div>

    <div v-else>{{ $t('exhibitors.empty') }}</div>
  </div>
</template>

<script>
import LayoutUtil from '@/helpers/layout/layout.helper';
import Exhibitor from './exhibitor/Exhibitor.vue';

export default {
  name: 'Exhibitors',
  components: { Exhibitor },
  props: {
    exhibitors: {
      type: Array,
      required: true,
    },
  },
  computed: {
    hasExhibitors() {
      return (this.exhibitors || []).length > 0;
    },
  },
  methods: {
    getThemeColor(index) {
      return LayoutUtil.getThemeColor(index);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/core/variables';

.exhibitor-list {
  &__item {
    flex: 1 0 100%;
  }
}
</style>
