<template>
  <v-sheet
    color="white"
    height="100%"
    class="exhibitor-preview d-flex mx-2 align-center justify-center"
    outlined
    rounded="lg"
  >
    <v-btn
      v-if="exhibitors.length > 1"
      class="flex-grow-0 pa-0 rounded-l-lg"
      depressed
      min-width="52px"
      large
      :x-large="!isMobile"
      color="white"
      @click="disconnect"
      :to="{
        name: 'Exhibitor',
        params: {
          eventId,
          exhibitorId: prevExhibitorId(),
        },
      }"
    >
      <v-icon dark :x-large="!isMobile"> mdi-chevron-left</v-icon>
    </v-btn>

    <div class="d-flex align-center justify-center flex-grow-1 px-4 text-h6 text-md-h5 kiosk-title">
      {{ exhibitor.name }}
    </div>

    <v-btn
      v-if="exhibitors.length > 1"
      class="flex-grow-0 pa-0 rounded-r-lg"
      depressed
      min-width="52px"
      large
      :x-large="!isMobile"
      color="white"
      @click="disconnect"
      :to="{
        name: 'Exhibitor',
        params: {
          eventId,
          exhibitorId: nextExhibitorId(),
        },
      }"
    >
      <v-icon dark :x-large="!isMobile"> mdi-chevron-right</v-icon>
    </v-btn>
  </v-sheet>
</template>

<script>
import { APP_EXHIBITOR_MODULE } from '@/stores/umanize-app/actions/exhibitor/app-exhibitor.actions';
import { mapGetters } from 'vuex';

export default {
  name: 'ExhibitorPreview',
  components: {},
  computed: {
    ...mapGetters(APP_EXHIBITOR_MODULE, ['exhibitors', 'exhibitor']),
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    eventId() {
      return this.$route.params.eventId;
    },
  },
  methods: {
    nextExhibitorId() {
      if (this.exhibitors && this.exhibitors.length) {
        const index = this.exhibitors.findIndex((x) => x.id === this.exhibitor.id);
        const nextExhibitor = this.exhibitors[index + 1];
        return nextExhibitor ? nextExhibitor.id : this.exhibitors[0].id;
      }
      return this.exhibitor.id;
    },
    prevExhibitorId() {
      if (this.exhibitors && this.exhibitors.length) {
        const index = this.exhibitors.findIndex((x) => x.id === this.exhibitor.id);
        const prevExhibitor = this.exhibitors[index - 1];
        return prevExhibitor ? prevExhibitor.id : this.exhibitors[this.exhibitors.length - 1].id;
      }
      return this.exhibitor.id;
    },
    disconnect() {
      this.$emit('disconnect');
    },
  },
};
</script>

<style lang="scss" scoped>
.exhibitor-preview {
  background-color: white;
  z-index: 1;
  position: relative;
}
</style>
