var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"exhibitor-preview d-flex mx-2 align-center justify-center",attrs:{"color":"white","height":"100%","outlined":"","rounded":"lg"}},[(_vm.exhibitors.length > 1)?_c('v-btn',{staticClass:"flex-grow-0 pa-0 rounded-l-lg",attrs:{"depressed":"","min-width":"52px","large":"","x-large":!_vm.isMobile,"color":"white","to":{
      name: 'Exhibitor',
      params: {
        eventId: _vm.eventId,
        exhibitorId: _vm.prevExhibitorId(),
      },
    }},on:{"click":_vm.disconnect}},[_c('v-icon',{attrs:{"dark":"","x-large":!_vm.isMobile}},[_vm._v(" mdi-chevron-left")])],1):_vm._e(),_c('div',{staticClass:"d-flex align-center justify-center flex-grow-1 px-4 text-h6 text-md-h5 kiosk-title"},[_vm._v(" "+_vm._s(_vm.exhibitor.name)+" ")]),(_vm.exhibitors.length > 1)?_c('v-btn',{staticClass:"flex-grow-0 pa-0 rounded-r-lg",attrs:{"depressed":"","min-width":"52px","large":"","x-large":!_vm.isMobile,"color":"white","to":{
      name: 'Exhibitor',
      params: {
        eventId: _vm.eventId,
        exhibitorId: _vm.nextExhibitorId(),
      },
    }},on:{"click":_vm.disconnect}},[_c('v-icon',{attrs:{"dark":"","x-large":!_vm.isMobile}},[_vm._v(" mdi-chevron-right")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }